// clientName MUST be specified, as this is used to build the API URL
// Regex for one lower, one upper, one special, one number, at least 8 characters =
//     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";
const clientName = 'cbre'

const ClientConfig = {
  clientName: clientName,
  environment: {
    development: {
      login: 'login',
      ssoLogin: 'https://api.beamapi.localdev:81/v1/authentication/sso?sp=default-sp&redirect_url=https://beam.localdev:8080',
      logout: 'login'
    },
    production: {
      login: 'login',
      ssoLogin: [`https://authenticator.monigle.net/v1/authentication/sso?sp=prod-sp-${clientName}&redirect_url=https://${clientName}.beam3.monigle.net`],
      logout: 'login'
    },
    qa: {
      login: 'login',
      ssoLogin: `https://beam3api.monigle3.net/v1/authentication/sso?sp=qa-sp-onelogin&redirect_url=https://${clientName}.beam3.monigle3.net`,
      logout: 'login'
    },
    staging: {
      login: 'login',
      ssoLogin: [`https://beam3api.monigle2.net/v1/authentication/sso?sp=staging-sp-${clientName}&redirect_url=https://${clientName}.beam3.monigle2.net`],
      logout: 'login'
    },
    demo: {
      login: 'login',
      ssoLogin: `https://beam3api-demo.monigle3.net/v1/authentication/sso?sp=demo-sp&redirect_url=https://${clientName}-demo.beam3.monigle3.net`,
      logout: 'login'
    }
  },
  settings: {
    // passwordRegex: '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    passwordRequirements: {
      length: 8,
      lowercase: 1,
      number: 1,
      symbol: 1,
      uppercase: 1
    }
  },
  theme: {
    themeStyles: {
      hasAltHomepageDesktopLogo: false
    }
  },
  include_modules: [],
  exclude_modules: [],
  request_headers: require('@core/js/config/request-headers')
}
export default ClientConfig
