/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {}
  }
}
